<template>
  <b-modal
    id="modal-course"
    centered
    size="xl"
    hide-footer
    title="ADD NEW RECORD"
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form
          @submit.prevent="handleSubmit(submitModal)"
          @reset.prevent="resetForm"
        >
          <b-row class="m-2">
            <!-- Label: Record For -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Record For"
                rules="required"
              >
                <b-form-group
                  label="Record For:"
                  label-for="authority_id"
                  label-cols-md="4"
                  class="required"
                >
                  <!-- <b-form-input v-model="newRecord.authority" id="authority"
                            placeholder="Enter speaker or authority" /> -->
                  <div class="form-col-select">
                    <v-select
                      v-model="newRecord.authority_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="licenseAuthorityOptions"
                      :clearable="false"
                      label="authority_name"
                      :reduce="(option) => option.id"
                      placeholder="SFC/IA"
                    />

                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Date -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Date"
                rules="required"
              >
                <b-form-group
                  label="Date:"
                  label-for="course_date"
                  label-cols-md="4"
                  class="required"
                >
                  <!-- <flat-pickr v-model="newRecord.course_date" class="form-control"
                            :config="flatDateConfig" placeholder="YYYY-MM-DD"></flat-pickr> -->
                  <flat-pickr
                    v-model="newRecord.course_date"
                    class="form-control"
                    :config="flatDateConfig"
                    placeholder="YYYY-MM-DD"
                    name="date"
                  />
                  <!-- <flat-pickr v-model="newRecord.course_date" /> -->
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Staff -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Staff"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label-for="staff_id"
                  label="Staff:"
                  label-cols-md="4"
                >
                  <div class="form-col-select">
                    <v-select
                      id="staff_id"
                      v-model="newRecord.user_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Enter Staff"
                      multiple
                      @input="(val) => handleUserSelection(val)"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Topic -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Topic"
                rules="required"
              >
                <b-form-group
                  label="Topic"
                  label-for="topic"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    v-model="newRecord.topic"
                    id="topic"
                    placeholder="Enter Topic"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Organiser -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Organiser"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Organiser:"
                  label-for="course_organization_id"
                  label-cols-md="4"
                >
                  <!-- <b-form-input v-model="newRecord.course_organization_id" id="course_organization_id"
                          placeholder="Enter speaker or course_organization_id" /> -->
                  <div class="form-col-select">
                    <v-select
                      v-model="newRecord.course_organization_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="organizationOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Enter speaker or organization"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Label: Organiser Name -->
            <b-col cols="12" v-if="newRecord.course_organization_id == 7">
              <validation-provider
                #default="validationContext"
                name="Organiser"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Organiser Name"
                  label-for="organization_name"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="newRecord.organization_name"
                    id="organization_name"
                    placeholder="Enter speaker or organization_name"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Ethics & Compliance Topic Related -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Ethics & Compliance Topic Related"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label-for="ethics_compliance"
                  label="Ethics & Compliance Topic Related:"
                  label-cols-md="4"
                >
                  <div class="form-col-select">
                    <v-select
                      id="ethics_compliance"
                      v-model="newRecord.ethics_compliance"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="yesNoOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Yes/No"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Proposed Hours -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Proposed Hours"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Proposed Hours:"
                  label-for="counted_hours"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="newRecord.counted_hours"
                    id="counted_hours"
                    placeholder="Enter Proposed Hours"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Topics Directly Relevant to the Regulated Activities -->
            <b-col
              v-if="
                newRecord.authority_id == 1 || newRecord.authority_id == 'b'
              "
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Topics Directly Relevant to the Regulated Activities"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label-for="relevant_topic"
                  label="Topics Directly Relevant to the Regulated Activities:"
                  label-cols-md="4"
                >
                  <div class="form-col-select">
                    <v-select
                      id="relevant_topic"
                      v-model="newRecord.relevant_topic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="yesNoOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Yes/No"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Label: Certificate -->
            <b-col cols="12">
              <b-form-group
                label="Certificate:"
                label-for="file"
                label-cols-md="4"
              >
                <!-- <b-form-file v-model="newRecord.file" id="file"
                          placeholder="Select a file or drag and drop here" drop-placeholder="Drop file here..."
                          multiple /> -->
                <el-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  ref="upload_image"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="image_files"
                  multiple
                  :limit="10"
                  :data="newRecord"
                  :on-change="onChange"
                  :on-remove="onChange"
                  :auto-upload="false"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <!-- accept=".jpg, .png, .gif, .pdf, .jpeg" -->
              </b-form-group>
            </b-col>
            <!-- Label: Certificate -->
            <!-- accept=".jpg, .png, .gif, .pdf, .jpeg" -->
            <!-- <b-col cols="12">
              <b-form-group label="Certificate:" label-for="file" label-cols-md="4">
                <b-form-file
                  v-model="newRecord.file"
                  id="file"
                  placeholder="Select a file or drag and drop here"
                  drop-placeholder="Drop file here..."
                  multiple
                />
              </b-form-group>
            </b-col> -->
          </b-row>
          <div class="modal-bottom">
            <!-- @click="resetForm" -->
            <b-button
              type="reset"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
            >
              Reset
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="hideModal"
              variant="outline-danger"
              class="mr-1"
            >
              Cancel
            </b-button>
            <!-- @click="submitModal" -->
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardBody,
  BCard,
  BFormInvalidFeedback,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import userStoreModule from "@/views/user/userStoreModule";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    BFormFile,
  },
  props: {
    organizationOptions: {
      type: Array,
      required: true,
    },
    licenseAuthorityOptions: {
      type: Array,
      required: true,
    },
    yesNoOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    // onChange(file, fileLists) {
    //   this.newRecord.file = [];
    //   fileLists.forEach((item) => {
    //     this.newRecord.file.push(item.raw);
    //   });
    //   console.log("image_files", this.newRecord.file);
    // },
    onChange(file, fileLists) {
      this.newRecord.file = [];
      fileLists.forEach((item) => {
        if (
          item.raw.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // word file ".docx"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/word.png";
        } else if (
          item.raw.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          // excel file ".xlsx"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/excel.png";
        } else if (item.raw.type == "application/pdf") {
          // pdf file ".pdf"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/pdf.png";
        } else if (
          item.raw.type != "image/jpeg" &&
          item.raw.type != "image/jpg" &&
          item.raw.type != "image/png" &&
          item.raw.type != "image/gif" &&
          item.raw.type != "image/webp" &&
          item.raw.type != "image/svg"
        ) {
          // files other than images
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/others.jpeg";
        }
        this.newRecord.file.push(item.raw);
      });
      console.log("image_files", this.newRecord.file);
    },
    handleUserSelection(val) {
      if (val.includes(0)) {
        this.newRecord.user_id.length = 0;
        this.userOptions
          .filter((x) => x.id != 0)
          .forEach((user) => {
            return this.newRecord.user_id.push(user.id);
          });
      }
    },
    hideModal() {
      this.$bvModal.hide("modal-course");
    },
    resetForm() {
      this.newRecord = {
        id: 0,
        authority_id: "",
        course_date: null,
        user_id: [],
        topic: "",
        course_organization_id: "",
        organization_name: null,
        ethics_compliance: "",
        relevant_topic: "",
        counted_hours: "",
        file: [],
      };
    },
    submitModal() {
      console.log(this.newRecord);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let array = this.newRecord.course_date.split("-");
          // this.loading = false;
          let formData = new FormData();

          this.newRecord.user_id.forEach((item) => {
            formData.append("user_id[]", item);
          });
          this.newRecord.file.forEach((item) => {
            formData.append("file[]", item);
          });
          formData.append("id", this.newRecord.id);
          formData.append("authority_id", this.newRecord.authority_id);
          formData.append("course_date", this.newRecord.course_date);
          formData.append("year", array[0]);
          formData.append(
            "organization_name",
            this.newRecord.organization_name
          );
          formData.append("topic", this.newRecord.topic);
          formData.append(
            "course_organization_id",
            this.newRecord.course_organization_id
          );
          formData.append(
            "ethics_compliance",
            this.newRecord.ethics_compliance
          );
          formData.append("relevant_topic", this.newRecord.relevant_topic);
          formData.append("counted_hours", this.newRecord.counted_hours);
          // formData.append('file', this.newRecord.file);
          console.log(formData);
          store
            .dispatch("app-user/addNewCourseRecord", formData)
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hideModal();
              this.resetForm();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const loading = ref(false);

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const newRecord = ref({
      id: 0,
      authority_id: "",
      course_date: null,
      user_id: [],
      topic: "",
      course_organization_id: "",
      organization_name: null,
      ethics_compliance: "",
      relevant_topic: "",
      counted_hours: "",
      file: [],
    });

    const temRecord = {
      id: 0,
      authority_id: "",
      course_date: null,
      user_id: [],
      topic: "",
      course_organization_id: "",
      organization_name: null,
      ethics_compliance: "",
      relevant_topic: "",
      counted_hours: "",
      file: [],
    };
    const uploadUrl = ref(
      process.env.VUE_APP_SERVICE_URL + "/api/project/uploadFile"
    );
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const resetRecordData = () => {
      newRecord = JSON.parse(JSON.stringify(temRecord));
    };

    const { refFormObserver, getValidationState } =
      formValidation(resetRecordData);

    return {
      newRecord,
      refFormObserver,
      getValidationState,
      resetRecordData,
      loading,
      uploadUrl,
      headerObj,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
